<template>
  <div class="home">
    <div class="login-con">
      <div class="myname">
        <!-- <img src="@/assets/logoblue.svg" alt="" class="logo-pic"> -->
        欢迎使用智慧考场
      </div>
      <a-form class="info-con">
        <a-form-item>
          <a-input
            :maxLength="18"
            size="large"
            placeholder="请输入账户名"
            v-model="account"
            class="login-input"
            @keyup.enter.native="onSubmit"
          >
            <a-icon slot="prefix" type="user" />
          </a-input>
        </a-form-item>
        <a-form-item>
          <a-input
            :maxLength="20"
            size="large"
            placeholder="请输入密码"
            type="password"
            v-model="password"
            class="login-input"
            @keyup.enter.native="onSubmit"
          >
            <a-icon slot="prefix" type="lock" />
          </a-input>
        </a-form-item>
        <div>
          <a-checkbox :checked="rember" @click="changRember">
            记住密码
          </a-checkbox>
          <!--<a style="float: right">忘记密码</a>-->
        </div>
        <a-form-item>
          <a-button
            :loading="logging"
            style="width: 100%; margin-top: 24px;"
            size="large"
            type="primary"
            @click="onSubmit"
            @keyup.enter.native="onSubmit"
          >
            登录
          </a-button>
        </a-form-item>
        <div></div>
      </a-form>
    </div>
    <!-- 底部公司信息 -->
    <div class="footer-info">
      本产品由 . 陕西旭梦承星科技有限公司开发
      <span style="margin: 0 20px;">©2021 cxkj</span>
      <a href="https://www.xmcxkj.net/" target="_black">
        公司官网:https://www.xmcxkj.net/
      </a>
    </div>
    <div class="bg"></div>
  </div>
</template>
<script>
// @ is an alias to /src
export default {
  name: 'login',
  data() {
    return {
      logging: false,
      error: '',
      //account: "super",//610101198805010002
      account: '', //610101198805010002
      password: '', //010002
      rember: true,
    }
  },
  mounted() {
    setTimeout(() => {
      this.msg = '222'
    }, 2000)
    this.findAccount()
  },
  methods: {
    changRember() {
      this.rember = !this.rember
    },
    findAccount() {
      let accountInfo = this.getCookie('accountInfo')
      //如果cookie里没有账号信息
      if (Boolean(accountInfo) == false) {
        return false
      } else {
        //如果cookie里有账号信息
        let account = ''
        let passWord = ''
        let index = accountInfo.indexOf('&')
        account = accountInfo.substring(0, index)
        passWord = accountInfo.substring(index + 1)
        this.account = account
        this.password = passWord
        this.rember = true
      }
    },
    onSubmit(e) {
      if (!this.account) {
        this.$info({
          title: '警告',
          content: '请输入登录账号！',
          onOk() {},
        })
        return
      }
      if (!this.password) {
        this.$info({
          title: '警告',
          content: '请输入登录密码！',
          onOk() {},
        })
        return
      } else {
        if (!this.password.length >= 6) {
          this.$info({
            title: '警告',
            content: '密码不得小于六位！',
            onOk() {},
          })
          return
        }
      }
      e.preventDefault()
      this.$post(this.$url.登录, {
        account: this.account,
        password: this.password,
      })
        .then((data) => {
          if (this.rember) {
            this.setCookie(
              'accountInfo',
              this.account + '&' + this.password,
              1440 * 3,
            )
          } else {
            this.delCookie('accountInfo')
          }
          this.$store.commit('updateCompanyId', data.companyId)
          this.$store.commit('updateCompanyName', data.companyName)
          this.$store.commit('updateBusRole', data.moduleExamBusRoleName)
          sessionStorage.setItem('companyId', data.companyId)
          sessionStorage.setItem('busRole', data.moduleExamBusRoleName)
          sessionStorage.setItem('token', data.token)
          this.$store.commit('updateLoad', '')
          this.getMenu()
        })
        .catch((err) => {
          console.log('err', err)
        })
    },
    getMenu() {
      this.$router.push('/body')
    },
    setCookie: function (c_name, value, expiremMinutes) {
      var exdate = new Date()
      exdate.setTime(exdate.getTime() + expiremMinutes * 60 * 1000)
      document.cookie =
        c_name +
        '=' +
        escape(value) +
        (expiremMinutes == null ? '' : ';expires=' + exdate.toGMTString())
    },
    // 读取cookie
    getCookie: function (c_name) {
      if (document.cookie.length > 0) {
        var c_start = document.cookie.indexOf(c_name + '=')
        if (c_start != -1) {
          c_start = c_start + c_name.length + 1
          var c_end = document.cookie.indexOf(';', c_start)
          if (c_end == -1) c_end = document.cookie.length
          return unescape(document.cookie.substring(c_start, c_end))
        }
      }
      return ''
    },
    // 删除cookie
    delCookie: function (c_name) {
      var exp = new Date()
      exp.setTime(exp.getTime() - 1)
      var cval = this.getCookie(c_name)
      if (cval != null) {
        document.cookie = c_name + '=' + cval + ';expires=' + exp.toGMTString()
      }
    },
  },
}
</script>
<style scoped>
.footer-info {
  position: fixed;
  bottom: 20px;
  width: 100%;
  text-align: center;
  color: #aaa;
}
.logo-pic {
  width: 60px;
  display: block;
  margin: 0 auto;
}
.info-con {
  padding: 0 30px;
}
.myname {
  border-top: solid 0px #092c4d;
  color: #1890ff;
  text-align: center;
  line-height: 40px;
  margin-bottom: 20px;
  font-size: 28px;
  padding-top: 20px;
  font-weight: bold;
  letter-spacing: 5px;
}
.bg {
  height: 250px;
  background: #1890ff;
  position: fixed;
  top: 50%;
  margin-top: -80px;
  left: 0;
  right: 0;
  z-index: -1;
}
.home {
  height: 100%;
  background: url(./../assets/bg.svg) 0 0 no-repeat;
  background-size: 100% 100%;
}
.login-con {
  width: 90%;
  max-width: 380px;
  margin: 0 auto;
  height: 320px;
  position: relative;
  top: 50%;
  margin-top: -250px;
  background: #fff;
  border-radius: 2px;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}
</style>
